/* ==================================================================================
   Body
   ================================================================================== */
body {
  margin: 0;
  overflow-y: scroll;
}
/* ==================================================================================
   Keditor UI
   ================================================================================== */
.keditor-ui {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
}
.keditor-ui,
.keditor-ui:before,
.keditor-ui:after {
  box-sizing: border-box;
}
.keditor-ui small,
.keditor-ui .small {
  font-size: 88%;
}
/* ==================================================================================
   opened-keditor-sidebar
   ================================================================================== */
body.opened-keditor-sidebar .keditor-content-area {
  margin-right: 260px;
}
body.opened-keditor-sidebar #keditor-sidebar {
  right: 0;
}
/* ==================================================================================
   highlighted-container-content
   ================================================================================== */
body.highlighted-container-content .keditor-container {
  margin: 30px 0 !important;
}
body.highlighted-container-content .keditor-container:before {
  display: none !important;
}
.keditor-highlighted-dropzone.keditor-container-content:before {
  border-color: #2eb2e4;
  border-width: 2px;
  z-index: 99;
}
/* ==================================================================================
   Frame
   ================================================================================== */
.keditor-frame {
  width: 100%;
  height: 500px;
  border: 1px solid #ddd;
}
/* ==================================================================================
   Content area
   ================================================================================== */
.keditor-content-area {
  min-height: 100px;
  width: auto;
  margin: 0;
  padding: 80px 50px 30px;
}
/* ==================================================================================
   Sidebar
   ================================================================================== */
#keditor-sidebar {
  position: fixed;
  z-index: 1031;
  top: 0;
  right: -255px;
  bottom: 0;
  background: #f0f0f0;
  opacity: .9;
  border-left: 1px solid #ddd;
  width: 255px;
  box-shadow: -1px 0 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font: 14px/1.42857143 "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
}
#keditor-sidebar:hover {
  opacity: 1;
}
#keditor-sidebar-toggler {
  position: absolute;
  top: 100px;
  width: 25px;
  height: 30px;
  left: -25px;
  background: #f0f0f0;
  text-align: center;
  line-height: 30px;
  border: 1px solid #ddd;
  border-right: 0;
  cursor: pointer;
  border-radius: 3px 0 0 3px;
  color: #666;
}
#keditor-sidebar-toggler > i.fa {
  line-height: inherit;
}
/* ==================================================================================
   KEditor Tab
   ================================================================================== */
.keditor-tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
.keditor-tabs .keditor-tab {
  display: table-cell;
  width: 1%;
  position: relative;
}
.keditor-tabs .keditor-tab a {
  display: block;
  border-radius: 3px 3px 0 0;
  height: 42px;
  padding: 10px 5px;
  text-align: center;
  border: 1px solid transparent;
  border-bottom-color: #ddd;
  color: #aaa;
}
.keditor-tabs .keditor-tab.active a {
  font-weight: bold;
  border-color: #ddd;
  border-bottom-color: #fff;
  background: #fff;
  color: #333;
}
/* ==================================================================================
   KEditor Tab Contents
   ================================================================================== */
.keditor-tabs-content .keditor-tab-content {
  display: none;
}
.keditor-tabs-content .keditor-tab-content.active {
  display: block;
}
/* ==================================================================================
   Snippets
   ================================================================================== */
#keditor-snippets-list {
  height: 100%;
}
#keditor-snippets-list .keditor-snippet:hover {
  opacity: .6;
  border-color: #ccc;
}
#keditor-snippets-list .keditor-snippet.not-matched {
  display: none;
}
#keditor-snippets-container {
  height: calc(100% - 42px);
  background: #fff;
  padding: 10px 5px;
  border: solid #ddd;
  border-width: 0 1px 1px;
}
#keditor-snippets-container > .keditor-tab-content {
  height: 100%;
}
#keditor-snippets-container > .keditor-tab-content > .keditor-tab-content-inner {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
#keditor-snippets-container > .keditor-tab-content > .keditor-tab-content-inner:before,
#keditor-snippets-container > .keditor-tab-content > .keditor-tab-content-inner:after {
  content: " ";
  display: table;
}
#keditor-snippets-container > .keditor-tab-content > .keditor-tab-content-inner:after {
  clear: both;
}
#keditor-snippets-container > .keditor-tab-content > .keditor-snippets-filter-wrapper {
  margin: 0 5px;
}
#keditor-snippets-container > .keditor-tab-content > .keditor-snippets-filter-wrapper .keditor-snippets-filter,
#keditor-snippets-container > .keditor-tab-content > .keditor-snippets-filter-wrapper .keditor-snippets-search {
  border: 1px solid #ddd;
  height: 34px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
#keditor-snippets-container > .keditor-tab-content > .keditor-snippets-filter-wrapper + .keditor-tab-content-inner {
  height: calc(100% - 88px);
}
.keditor-snippet {
  padding: 15px;
  border: 1px solid #f0f0f0;
  cursor: move;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  float: left;
  background: #fff;
  margin: 0 5px 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.keditor-snippet img {
  display: block;
}
.keditor-snippet:last-child {
  margin-bottom: 0;
}
/* ==================================================================================
   Settings panel
   ================================================================================== */
#keditor-setting-panel {
  background: #fff;
  position: absolute;
  z-index: 1001;
  top: 10px;
  width: calc(100% - 20px);
  right: -100%;
  bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.opened-keditor-setting #keditor-setting-panel {
  right: 10px;
}
#keditor-setting-header {
  height: 42px;
  border-bottom: 1px solid #ddd;
  padding: 10px 30px 10px 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
#keditor-setting-header:before,
#keditor-setting-header:after {
  content: " ";
  display: table;
}
#keditor-setting-header:after {
  clear: both;
}
#keditor-setting-title {
  color: #666;
  font-weight: bold;
}
#keditor-setting-closer {
  text-decoration: none;
  color: #999999;
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 10px;
  width: 21px;
  text-align: center;
  line-height: 21px;
}
#keditor-setting-closer:hover {
  color: #666;
}
#keditor-setting-body {
  position: absolute;
  top: 42px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
}
#keditor-setting-forms {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
#keditor-setting-forms .keditor-setting-form {
  overflow: hidden;
  display: none;
  min-height: 100%;
}
#keditor-setting-forms .keditor-setting-form.active {
  display: block;
}
/* ==================================================================================
   Toolbar
   ================================================================================== */
.keditor-toolbar {
  position: absolute;
  display: none;
  z-index: 5;
  font: 14px/1.42857143 "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.keditor-toolbar > a {
  padding: 1px 3px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: block;
  text-align: center;
  line-height: 20px;
}
.keditor-toolbar > a .fa {
  line-height: inherit;
}
.keditor-toolbar .btn-container-reposition,
.keditor-toolbar .btn-component-reposition {
  cursor: move;
}
/* ==================================================================================
   Container
   ================================================================================== */
.keditor-container {
  position: relative;
}
.keditor-container:before {
  position: absolute;
  top: -3px;
  right: -3px;
  left: -3px;
  bottom: -3px;
  border: 3px dashed transparent;
  content: " ";
  display: block;
}
.keditor-container > .keditor-toolbar {
  top: -3px;
  left: -39px;
  border: 1px solid #333;
  border-radius: 3px;
  overflow: hidden;
}
.keditor-container > .keditor-toolbar > a {
  background: #333;
  color: #fff;
}
.keditor-container > .keditor-toolbar > a:hover {
  background: #2eb2e4;
}
.keditor-container:hover:before {
  border-color: #b5e4f6;
}
.keditor-container.showed-keditor-toolbar:before {
  border-color: #2eb2e4;
}
.keditor-container.showed-keditor-toolbar > .keditor-toolbar {
  display: block;
}
.keditor-container-content {
  min-height: 50px !important;
  position: relative;
}
.keditor-container-content:before {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  content: " ";
  display: block;
  border: 1px dashed #e0e0e0;
}
/* ==================================================================================
   Component
   ================================================================================== */
.keditor-component {
  position: relative;
}
.keditor-component:before {
  position: absolute;
  top: -2px;
  right: -2px;
  left: -2px;
  bottom: -2px;
  content: " ";
  border: 2px solid rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 1;
}
.keditor-component .keditor-toolbar {
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0 2px 2px;
  text-align: left;
}
.keditor-component .keditor-toolbar > a {
  border: 0;
  background: none;
  color: #fff;
  text-align: center;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
}
.keditor-component .keditor-toolbar > a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.keditor-component .keditor-component-content {
  position: relative;
  z-index: 2;
}
.keditor-component:hover:before,
.keditor-component.showed-keditor-toolbar:before {
  display: block;
}
.keditor-component.showed-keditor-toolbar .keditor-component-content {
  outline: none !important;
}
.keditor-component:hover {
  z-index: 2;
}
.keditor-component:hover .keditor-toolbar {
  display: block;
}
/* ==================================================================================
   Drag n Drop helper
   ================================================================================== */
.keditor-ui-dragging {
  display: block !important;
  opacity: 0.5 !important;
}
.keditor-ui-dragging .keditor-toolbar {
  display: none !important;
}
.ui-draggable-dragging.keditor-snippet {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.ui-sortable-placeholder.keditor-snippet,
.ui-sortable-placeholder.keditor-container,
.ui-sortable-placeholder.keditor-component {
  background: #eee !important;
  visibility: visible !important;
  height: 20px !important;
  margin: 5px 0 !important;
  float: none !important;
  outline: none !important;
  padding: 0 !important;
}
.ui-sortable-placeholder.keditor-snippet:before,
.ui-sortable-placeholder.keditor-container:before,
.ui-sortable-placeholder.keditor-component:before,
.ui-sortable-placeholder.keditor-snippet:after,
.ui-sortable-placeholder.keditor-container:after,
.ui-sortable-placeholder.keditor-component:after {
  display: none !important;
}
.ui-sortable-helper.keditor-section {
  background: #fff !important;
  opacity: 0.5;
}
.ui-sortable-helper.keditor-container:before,
.ui-sortable-helper.keditor-component:before,
.ui-sortable-helper.keditor-container .keditor-toolbar,
.ui-sortable-helper.keditor-component .keditor-toolbar {
  display: none !important;
}
